
<div>
    <div class="icon-container" @click="show = !show; trackClick('header', 'Buscar_en_el_sitio')">
        <img v-img-prefix :src="`/public-assets/search-white`" alt="search-icon" class="icon" width="24"
             height="23">
        <div v-show="!show">
            <div class="caret"></div>
            <div class="icon-content">
                <span>Buscar en el sitio</span>
            </div>
        </div>
    </div>
    <div v-if="show">
        <SearchContainer :sections="sections" :trending="trending"/>
    </div>
</div>
